import { normalizeUrl } from "./url";

// import css from "dom-css";

let scrollbarWidth: false | number = false;
export const getScrollbarWidth = () => {
    if (scrollbarWidth !== false) return scrollbarWidth;
    /* istanbul ignore else */
    if (typeof document !== "undefined") {
        const div = document.createElement("div");
        Object.assign(div.style, {
            width: 100,
            height: 100,
            position: "absolute",
            top: -9999,
            overflow: "scroll",
            MsOverflowStyle: "scrollbar",
        });
        // css(div, {
        //     width: 100,
        //     height: 100,
        //     position: "absolute",
        //     top: -9999,
        //     overflow: "scroll",
        //     MsOverflowStyle: "scrollbar",
        // });
        document.body.appendChild(div);
        scrollbarWidth = (div.offsetWidth - div.clientWidth);
        document.body.removeChild(div);
    } else {
        scrollbarWidth = 0;
    }
    return scrollbarWidth || 0;
};


export const copyText = (text: string): void => {
    const textArea = document.createElement("textarea");

    textArea.value = text;
    Object.assign(textArea.style, {
        userSelect: "none",
        border: 0,
        width: 0,
        height: 0,
        position: "absolute",
        zIndex: -1000,
        top: 0,
        left: 0,
    } as React.CSSProperties);

    document.body.appendChild(textArea);
    textArea.select();

    document.execCommand("copy");
    document.body.removeChild(textArea);
};

export const htmlToDom = (html: string): Document => {
    const parser = new DOMParser();
    const dom = parser.parseFromString(html, "text/html");
    return dom;
};

export const isTextElement = (el: Element | Text): el is Text => (
    el.nodeType === Node.TEXT_NODE
);

export const xferElements = (elements: (Element | Text)[], to: Element, dom: Document): void => {
    for (const node of elements) {
        if (isTextElement(node)) {
            const el = dom.createTextNode(node.data);
            to.appendChild(el);
            continue;
        }

        const elem = dom.createElement(node.tagName);
        mirrorElement(node, elem, dom, true);

        to.appendChild(elem);
    }
};

export const mirrorElement = (from: Element, to: Element, dom = document, recursive = false): void => {
    if (!from || !to) return;
    const fromAttributes = Array.from(from.attributes);
    const toAttributes = Array.from(to.attributes);
    const removeAttributes = toAttributes.filter(({ name: toName }) => (
        !fromAttributes.some(({ name: fromName }) => toName === fromName)
    ));

    for (const attr of fromAttributes) {
        to.setAttribute(attr.name, attr.value);
    }
    for (const attr of removeAttributes) {
        to.removeAttribute(attr.name);
    }

    if (recursive && from.childNodes.length > 0) {
        xferElements(Array.from(from.childNodes) as Element[], to, dom);
    }
};

export const mirrorDom = (from: string | Document, to: Document = document): void => {
    const dom = typeof from === "string"
        ? htmlToDom(from)
        : from;

    for (const [elName, el] of Object.entries({ head: dom.head, body: dom.body })) {
        mirrorElement(el, to[elName as keyof Document] as Element, to, true);
    }
};

export function getFonts() {
    const fontFaces: string[] = [];
    const sheets = Array.from(document.styleSheets).filter((styleSheet) => (
        !styleSheet.href || styleSheet.href.startsWith(window.location.origin)
    ));
    for (let i = sheets.length; i >= 0; --i) {
        const ruleList = sheets[i]?.rules || sheets[i]?.cssRules || [];
        for (let j = ruleList.length; j >= 0; --j) {
            const cssRule = ruleList[j];
            if (cssRule instanceof CSSFontFaceRule) {
                fontFaces.push(
                    cssRule.cssText.replace(
                        /(url\(['"]?)(\/[^"'\]]+)(['"]?\))/g,
                        (m, p1, p2, p3) => (
                            p1 +
                            normalizeUrl(location.origin + p2) +
                            p3
                        ),
                    ),
                );
            }
        }
    }

    return fontFaces;
}

export function getLinks() {
    const linkEls = document.querySelectorAll("link[rel=preconnect][type^='font'], link[rel=preload][type^='font']");
    return Array.from(linkEls).map((el) => {
        const node = document.createElement("link");
        for (const attr of Array.from(el.attributes)) {
            if (attr.name === "href") {
                if (!attr.value?.startsWith("http") && !attr.value?.startsWith("//")) {
                    node.setAttribute("href", normalizeUrl(location.origin + attr.value));
                    continue;
                }
            }
            node.setAttribute(attr.name, attr.value);
        }
        return node.outerHTML;
    });
}