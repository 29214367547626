import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { convertAlignToFlex } from "@utils/styles";
import { Typography } from "~/components/Typography";
import Box from "@material-ui/core/Box";
import clsx from "clsx";

import { FlexTableRow } from "./FlexTableRow";

import type {
    IFlexTableRowBaseProps,
    IFlexTableRowGroup,
} from "./types";

const useStyles = makeStyles((theme) => ({
    rowGroup: {
        width: "100%",
        display: "flex",
        flexFlow: "column nowrap",
    },
    rowHeader: {
        // ...theme.typography.h3,
        color: theme.palette.text.primary,
        display: "flex",
        flexFlow: "row nowrap",
        width: "100%",
    },
}));

export type FlexTableRowGroupProps = IFlexTableRowBaseProps & IFlexTableRowGroup & {
    classes?: {
        row?: string;
    }
};

export const FlexTableRowGroup: React.FC<FlexTableRowGroupProps> = ({
    title,
    rows,
    onClick,
    className,
    classes = {},
    ...props
}) => {
    const styles = useStyles();

    const titleDetails = typeof title === "object"
        ? title
        : {
            value: title,
        };
    const titleStyle = titleDetails.style || {};
    const titleAlign = convertAlignToFlex("left", titleDetails.align);

    return (
        <Box
            className={clsx(styles.rowGroup, className)}
            style={{
                justifyContent: titleAlign,
                ...titleStyle,
            }}
            onClick={onClick}
        >
            <Typography variant="tableTitle" component="div" className={styles.rowHeader}>{titleDetails.value}</Typography>
            {rows.map((row, i) => (
                <FlexTableRow key={i} {...row} {...props} className={classes.row} />
            ))}
        </Box>
    );
};