import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { copyText } from "@utils/dom";
import { convertAlignToFlex } from "@utils/styles";
import type {
    IFlexTableRowBaseProps,
    IFlexTableRow,
    IFlexTableCellDetailed,
} from "./types";

const useStyles = makeStyles((theme) => ({
    flexRow: {
        ...theme.typography.body1,
        display: "flex",
        flexFlow: "row nowrap",
        width: "100%",
    },
    cell: {
        ".print &": {
            overflowWrap: "anywhere",
        },
    },
}));

export type FlexTableRowProps = IFlexTableRowBaseProps & IFlexTableRow;

export const FlexTableRow: React.FC<FlexTableRowProps> = ({
    cells,
    columns,
    rowNumber,
    onClick,
    copy,
    style,
    className,
}) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [textCopied, setTextCopied] = useState(false);
    const styles = useStyles();

    const onMouseOver = () => {
        if (!showTooltip) setShowTooltip(true);
    };

    const onMouseLeave = () => {
        const timeout = textCopied ? 1000 : 0;
        setTimeout(() => {
            setShowTooltip(false);
        }, timeout);
    };

    const copyHandler = () => {
        copyText(cells[1] as string);
        setTextCopied(true);

        setTimeout(() => {
            setShowTooltip(false);
        }, 1500);
        setTimeout(() => {
            setTextCopied(false);
        }, 1650);
    };

    return (
        <Box className={clsx(styles.flexRow, className)} style={style}>
            {columns.map((col, colIdx) => {
                const cell = cells[colIdx];
                const cellValue = col.cell
                    ? col.cell(cell, rowNumber, colIdx)
                    : cell;
                const cellDetails = (
                    typeof cellValue === "object" && (cellValue as IFlexTableCellDetailed)?.value !== undefined
                        ? cellValue
                        : {
                            value: cellValue,
                        }
                ) as Partial<IFlexTableCellDetailed>;

                const cellClassName = cellDetails.className;
                const cellStyle = cellDetails.style || col.style || {};
                const cellAlign = convertAlignToFlex("left", cellDetails.align || col.align);

                return (
                    <Box
                        key={`row-${rowNumber}-cell-${colIdx}`}
                        className={clsx(styles.cell, cellClassName)}
                        style={{
                            width: col.width,
                            display: "flex",
                            flexFlow: "row wrap",
                            justifyContent: cellAlign,
                            ...cellStyle,
                        }}
                        onClick={onClick}
                    >
                        {cellDetails.value}
                    </Box>
                );
            })}
            {copy && (
                <Tooltip
                    title={textCopied ? "Copied!" : "Copy"}
                    placement="left"
                    open={showTooltip}
                    disableHoverListener
                    onMouseOver={onMouseOver}
                    onMouseLeave={onMouseLeave}
                >
                    <IconButton size="small" onClick={copyHandler}>
                        <FileCopyOutlinedIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            )}
        </Box>
    );
};