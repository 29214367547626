import React from "react";
import { Theme, makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import clsx from "clsx";

import { FlexTableRow as FlexTableRowInternal } from "./FlexTableRow";
import { FlexTableRowGroup as FlexTableRowGroupInternal } from "./FlexTableRowGroup";

import type { FlexTableRowProps } from "./FlexTableRow";
import type { FlexTableRowGroupProps } from "./FlexTableRowGroup";

import type {
    FlexTableColumnDef,
    FlexTableOptions,
    IFlexTableRow,
    IFlexTableRowGroup,
} from "./types";

const useStyles = makeStyles<Theme, InternalFlexTableProps>((theme) => ({
    table: {
        position: "relative",
        display: "flex",
        flexFlow: ({ tableOpts }) => `column ${tableOpts?.columnWrap ? "wrap" : "nowrap"}`,
        width: "100%",
        height: ({ tableOpts }) => tableOpts?.columnWrap?.height || "auto",
        ".print &": {
            display: "block",
            height: "100%",
        },
    },
    row: {
        position: "relative",
        pageBreakInside: "avoid",
        breakInside: "avoid",
        padding: "12px 20px",
        verticalAlign: "top",
        width: ({ tableOpts }) => tableOpts?.rowWidth,
        ".print & *": {
            verticalAlign: "top",
            justifySelf: "flex-start",
            alignSelf: "flex-start",
        },
        "&:not(:last-child)": {
            borderBottom: `1px solid ${theme.palette.grey[500]}`,
            ".print &:not(.grouped)": {
                borderBottom: "none",
            },
        },
        ".print &:not(.grouped)": {
            padding: "0 20px !important",
        },
        ".print &.grouped": {
            padding: "0 20px 5px !important",
            display: "block",
        },
    },
    rowHover: {
        "&:hover": {
            cursor: "pointer",
            backgroundColor: theme.palette.action.hover,
            ".print &:hover": {
                cursor: "auto",
                backgroundColor: "inherit",
            },
        },
    },
}));

interface IFlexTableBaseProps {
    columns: FlexTableColumnDef[];
    tableOpts?: FlexTableOptions;
}

export interface IFlexTableRowsProps extends IFlexTableBaseProps {
    groupedRows?: false;
    classes?: {
        row?: string;
    }
    rows: IFlexTableRow[];
}

export interface IFlexTableGroupsProps extends IFlexTableBaseProps {
    groupedRows: true;
    classes?: {
        row?: string;
        group?: string;
    }
    groups: IFlexTableRowGroup[];
}

type FlexTableImgOpts = {
    src: string;
    alt: string;
    className?: string;
}
export type FlexTableProps = (IFlexTableGroupsProps | IFlexTableRowsProps) & {
    imgOpts?: FlexTableImgOpts;
}
type InternalFlexTableProps = FlexTableProps & {
    className?: string;
    classes?: {
        group?: string;
        row?: string;
    }
};

const isGroupedTable = (props: InternalFlexTableProps): props is IFlexTableGroupsProps => {
    return (props as IFlexTableGroupsProps).groupedRows === true;
};

const isGroupedRows = (rowProps: FlexTableRowProps | FlexTableRowGroupProps): rowProps is FlexTableRowGroupProps => {
    return (rowProps as FlexTableRowGroupProps).title !== undefined;
};

export const FlexTable: React.FC<InternalFlexTableProps> = ({
    classes = {},
    imgOpts,
    ...props
}) => {
    const styles = useStyles(props);

    const { columns, className } = props;

    const rows = isGroupedTable(props)
        ? props.groups
        : (props as IFlexTableRowsProps).rows;

    return (
        <Box className={clsx(styles.table, props.groupedRows && "grouped", className)}>
            {imgOpts && (
                <Box
                    style={{
                        display: "flex",
                        padding: "19px",
                        margin: "0 auto",
                        height: "299px",
                    }}
                    className={imgOpts.className}
                >
                    <img
                        src={imgOpts.src}
                        alt={imgOpts.alt}
                        width="100%"
                        height="auto"
                        style={{ objectFit: "contain" }}
                    />
                </Box>
            )}
            {(rows as (IFlexTableRow | IFlexTableRowGroup)[]).map((row, rowIdx) => {
                const { onClick } = row;

                const rowProps = {
                    columns,
                    rowNumber: rowIdx,
                    classes,
                    className: clsx({
                        "grouped": !!props.groupedRows,
                        [styles.rowHover]: !!onClick,
                        [styles.row]: true,
                    }),
                    ...row,
                };

                return (isGroupedRows(rowProps) ? (
                    <FlexTableRowGroupInternal key={`group-${rowIdx}`} {...rowProps} className={clsx(rowProps.className, classes.group)} />
                ) : (
                    <FlexTableRowInternal key={`row-${rowIdx}`} {...rowProps} className={clsx(rowProps.className, classes.row)} />
                ));
            })}
        </Box>
    );
};

export const FlexTableRow: React.FC<IFlexTableRowsProps> = (props) => (
    <FlexTable {...props} />
);

export const FlexTableRowGroup: React.FC<IFlexTableGroupsProps> = (props) => (
    <FlexTable {...props} />
);