import React from "react";
import { Theme, makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import clsx from "clsx";

import { convertAlignToFlex } from "@utils/styles";
import {
    FlexTable,
    FlexTableProps,
    IFlexTableGroupsProps,
    IFlexTableRowsProps,
} from "~/components/FlexTable/FlexTable";
import type { Alignment } from "@utils/styles";

const printHeaderHeight = "40px";

const useInfoCardStyles = makeStyles<Theme, IInfoCardProps>((theme) => ({
    root: {
        borderRadius: theme.shape.borderRadius,
        position: "relative",
        overflowX: "hidden",
        border: `1px solid ${theme.palette.grey[500]}`,
        backgroundColor: theme.palette.background.paper,
        height: "100%",
        display: "flex",
        flexFlow: "column nowrap",

        // ".print &": {
        //     display: "block",
        // },
    },
    header: {
        background: theme.palette.background.gradient.main,
        ...theme.typography.tableTitle,
        color: theme.palette.text.contrast,
        // height: 50,
        display: "flex",
        flexFlow: "row wrap",
        alignItems: "center",
        justifyContent: ({ headerProps = {} }) => convertAlignToFlex("left", headerProps.align),
        padding: "10px 20px",

        ".print &": {
            height: printHeaderHeight,
            padding: 10,
        },
    },
    body: {
        overflowX: "hidden",
        height: "100%",
        display: "flex",
        flexFlow: "column nowrap",
        ".print &": {
            // overflow: "hidden",
            height: "auto",
        },
        ".print .custom &": {
            height: `calc(100% - ${printHeaderHeight})`,
        },
    },
}));

export interface IHeaderProps {
    align: Alignment;
}

export type InfoCardClasses = "root" | "header" | "body";

interface IInfoCardProps {
    title?: string | React.ReactNode;
    headerProps?: IHeaderProps;
    className?: string;
    classes?: Partial<Record<InfoCardClasses, string>>;
}

export const InfoCard: React.FC<IInfoCardProps> = ({
    classes = {},
    ...props
}) => {
    const styles = useInfoCardStyles(props);

    const { title, className, children } = props;

    return (
        <Box className={clsx(styles.root, className)}>
            {title && <Box className={clsx(styles.header, classes.header)}>{title}</Box>}
            <Box className={clsx(styles.body, classes.body)}>
                {children}
            </Box>
        </Box>
    );
};

type InfoCardTableProps = FlexTableProps & IInfoCardProps;

export const InfoCardTable: React.FC<InfoCardTableProps> = (props) => {
    return (
        <InfoCard {...props}>
            <FlexTable {...props} />
        </InfoCard>
    );
};

export const InfoCardTableRow: React.FC<IFlexTableRowsProps & IInfoCardProps> = (props) => (
    <InfoCardTable {...props} />
);
export const InfoCardTableRowGroup: React.FC<IFlexTableGroupsProps & IInfoCardProps> = (props) => (
    <InfoCardTable {...props} />
);